import { createSlice } from "@reduxjs/toolkit";

const propertySlice = createSlice({
  name: "property",
  initialState: {
    loading: false,
    enquireloading:false,
    property:[],
    propertyList: [],
    isEnquirySuccess: false,
    count:0
  },
  reducers: {
    singlePropertyRequest(state, action) {
      return {
        loading: true,
      };
    },
    singlePropertySuccess(state, action) {
      return {
        ...state,
        loading: false,
        property: action.payload,
      };
    },
    singlePropertyFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    propertyRequest(state, action) {
      return {
        loading: true,
      };
    },
    propertySuccess(state, action) {
      return {
        ...state,
        loading: false,
        propertyList: action.payload,
      };
    },
    propertyFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    enquiryRequest(state, action) {
      return {
        enquireloading: true,
        ...state
      };
    },
    enquirySuccess(state, action) {
      return {
        ...state,
        enquireloading: false,
        isEnquirySuccess: true,
      };
    },
    enquiryFail(state, action) {
      return {
        enquireloading: false,
        isEnquirySuccess: false,
        // error: action.payload,
        ...state
      };
    },
    countSuccess(state, action) {
      return {
        ...state,
        count: action.payload,
      };
    },

    clearError(state, action) {
      return {
        ...state,
        error: null,
      };
    },
    clearProduct(state, action) {
      return {
        ...state,
        propertyList: {},
      };
    }
  },
});

const { actions, reducer } = propertySlice;

export const {
  singlePropertyRequest,
  singlePropertySuccess,
  singlePropertyFail,
  propertyRequest,
  propertySuccess,
  propertyFail,
  clearError,
  clearProduct,
  enquiryRequest,
  enquirySuccess,
  enquiryFail,
  countSuccess
} = actions;

export default reducer;
