import React from "react";
import Navbar from "../component/Navbar";
import Property from "../component/Properties/property";
import Footer from "../component/Footer";
import Switcher from "../component/Switcher";

export default function Index() {
  return (
    <>
      <Navbar />
      <section className="relative md:pb-24 pb-16 pt-5">
        <Property />
      </section>
      <Footer />
      {/* <Switcher /> */}
      {/* <!-- End --> */}
    </>
  );
}
