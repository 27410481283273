import React, { useState } from "react";
import { Link } from "react-router-dom";

import Image from "../assets/images/about.jpg";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

export default function About() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="container lg:mt-24 mt-16 pt-3 pb-10 mb-10">
        <div className="grid md:grid-cols-12 grid-cols-1  items-center gap-[30px]">
          <div className="md:col-span-4">
            <div className="relative">
              {/* <img src={Image} className="rounded-xl shadow-md" alt="" />
              <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                <Link
                  to="#"
                  onClick={() => setOpen(true)}
                  className="lightbox size-20 rounded-full shadow-md dark:shadow-gyay-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-green-600"
                >
                  <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                </Link>
              </div> */}

              <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal text-right font-semibold">
              Your Trusted Real Estate Partner
              </h4>
            </div>
          </div>

          <div className="md:col-span-8">
            <div className="lg:ms-4">
              <p className="text-slate-600">
                At myBrokerr, we connect property owners, buyers, and sellers to
                simplify the entire real estate process. Whether you're listing,
                purchasing, or selling a property, our platform streamlines
                every step from marketing to handling documentation and legalities to
                ensure smooth, efficient transactions.
                <br /> We focus on convenience and transparency, we’ve
                created a user-friendly system that guarantees a seamless
                experience for all parties involved. Say goodbye to the stress
                of traditional property dealings. We make real estate simpler,
                faster, and more accessible, helping you make smarter property
                decisions with ease.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
