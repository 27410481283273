import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { properties } from "../component/Properties/data";
import withRouter from "../component/withrouter";
import Navbar from "../component/Navbar";
import Switcher from "../component/Switcher";
import Footer from "../component/Footer";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

import Image1 from "../assets/images/property/single/1.jpg";
import Image2 from "../assets/images/property/single/2.jpg";
import Image3 from "../assets/images/property/single/3.jpg";
import Image4 from "../assets/images/property/single/4.jpg";
import Image5 from "../assets/images/property/single/5.jpg";
import { AiOutlineCamera } from "react-icons/ai";
import { LiaBathSolid, LiaCompressArrowsAltSolid } from "react-icons/lia";
import { BiBed } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import { getProperty } from "../actions/PropertyActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../component/Loader";

function PropertyDetail(props) {
  const dispatch = useDispatch();
  let params = useParams();
  let id = params.id;

  const [photoIndex, setActiveIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const handleCLick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };

  const { loading, property } = useSelector((state) => state.propertyState);

  //   const [Image1 = "", Image2 = "", Image3 = "", Image4 = "", Image5 = ""] =
  //     property.imageUrls.map((item) => item.image);
  const images = [Image1, Image2, Image3, Image4, Image5];

  console.log("property", property);
  useEffect(() => {
    dispatch(getProperty(id));
  }, [dispatch, id]);



  
  return (
    <>
      <Navbar />
      {/* <!-- Hero Start --> */}

      {loading ? (
        <Loader />
      ) : (
        <section className="relative md:pb-24 pb-16 mt-20">
          <div className="container-fluid">
            <div className="md:flex mt-4">
              <div className="lg:w-1/2 md:w-1/2 p-1">
                <div className="group relative overflow-hidden">
                  <img
                    src={property?.imageUrls[0].image}
                    alt=""
                    className="w-100"
                  />
                  <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                  <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                    <Link
                      to="#"
                      onClick={() => handleCLick(0)}
                      className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox"
                    >
                      <AiOutlineCamera className="text-lg" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="lg:w-1/2 md:w-1/2">
                <div className="flex">
                  <div className="w-1/2 p-1">
                    <div className="group relative overflow-hidden">
                      <img src={property?.imageUrls[1].image} alt="" />
                      <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                      <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                        <Link
                          to="#"
                          onClick={() => handleCLick(1)}
                          className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox"
                        >
                          <AiOutlineCamera className="text-lg" />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="w-1/2 p-1">
                    <div className="group relative overflow-hidden">
                      <img
                        src={
                          property?.imageUrls?.[2]?.image
                            ? property.imageUrls[2].image
                            : Image1
                        }
                        alt=""
                      />

                      <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                      <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                        <Link
                          to="#"
                          onClick={() => handleCLick(2)}
                          className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox"
                        >
                          <AiOutlineCamera className="text-lg" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <div className="w-1/2 p-1">
                    <div className="group relative overflow-hidden">
                      <img src={property?.imageUrls?.[3]?.image
                            ? property.imageUrls[3].image
                            : Image1} alt="" />
                      <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                      <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                        <Link
                          to="#"
                          onClick={() => handleCLick(3)}
                          className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox"
                        >
                          <AiOutlineCamera className="text-lg" />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="w-1/2 p-1">
                    <div className="group relative overflow-hidden">
                      <img src={property?.imageUrls?.[4]?.image
                            ? property.imageUrls[4].image
                            : Image1} alt="" />
                      <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                      <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                        <Link
                          to="#"
                          onClick={() => handleCLick(4)}
                          className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox"
                        >
                          <AiOutlineCamera className="text-lg" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container md:mt-24 mt-16">
            <div className="md:flex">
              <div className="lg:w-2/3 md:w-1/2 md:p-4 px-3">
                <h4 className="text-2xl font-medium">{property?.name}</h4>

                <ul className="py-6 flex items-center list-none">
                  <li className="flex items-center lg:me-6 me-4">
                    <LiaCompressArrowsAltSolid className="lg:text-3xl text-2xl me-2 text-green-600" />
                    <span className="lg:text-xl">{property?.square}sqft</span>
                  </li>

                  {/* <li className="flex items-center lg:me-6 me-4">
                                    <BiBed className="lg:text-3xl text-2xl me-2 text-green-600"/>
                                    <span className="lg:text-xl">{property?.beds} Beds</span>
                                </li>

                                <li className="flex items-center">
                                    <LiaBathSolid className="lg:text-3xl text-2xl me-2 text-green-600"/>
                                    <span className="lg:text-xl">{property?.baths} Baths</span>
                                </li> */}
                </ul>

                <p className="text-slate-400">{property?.description}</p>
                <p className="text-slate-400 mt-4">
                  But I must explain to you how all this mistaken idea of
                  denouncing pleasure and praising pain was born and I will give
                  you a complete account of the system, and expound the actual
                  teachings of the great explorer of the truth, the
                  master-builder of human happiness.
                </p>
                <p className="text-slate-400 mt-4">
                  Nor again is there anyone who loves or pursues or desires to
                  obtain pain of itself, because it is pain, but because
                  occasionally circumstances occur in which toil and pain can
                  procure him some great pleasure.
                </p>

                <div className="w-full leading-[0] border-0 mt-6">
                  <iframe
                    title="iframe"
                    src={property?.gmap}
                    style={{ border: "0" }}
                    className="w-full h-[500px]"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>

              <div className="lg:w-1/3 md:w-1/2 md:p-4 px-3 mt-8 md:mt-0">
                <div className="sticky top-20">
                  <div className="rounded-md bg-slate-50 dark:bg-slate-800 shadow dark:shadow-gray-700">
                    <div className="p-6">
                      <h5 className="text-2xl font-medium">Price:</h5>

                      <div className="flex justify-between items-center mt-4">
                        <span className="text-xl font-medium">
                          $ {property?.regularPrice}
                        </span>

                        {property?.propertyStatus === "Sold" ? (
                          <span className="bg-red-600/10 text-red-600 text-sm px-2.5 py-0.75 rounded h-6">
                            {property?.propertyStatus}
                          </span>
                        ) : (
                          <span className="bg-green-600/10 text-green-600 text-sm px-2.5 py-0.75 rounded h-6">
                            {property?.propertyStatus}
                          </span>
                        )}
                      </div>

                      {/* <ul className="list-none mt-4">
                                            <li className="flex justify-between items-center">
                                                <span className="text-slate-400 text-sm">Days on Hously</span>
                                                <span className="font-medium text-sm">124 Days</span>
                                            </li>

                                            <li className="flex justify-between items-center mt-2">
                                                <span className="text-slate-400 text-sm">Price per sq ft</span>
                                                <span className="font-medium text-sm">$ 186</span>
                                            </li>

                                            <li className="flex justify-between items-center mt-2">
                                                <span className="text-slate-400 text-sm">Monthly Payment (estimate)</span>
                                                <span className="font-medium text-sm">$ 1497/Monthly</span>
                                            </li>
                                        </ul> */}
                    </div>

                    <div className="flex">
                      <div className="p-1 w-1/2">
                        <Link
                          to="#"
                          className="btn bg-green-600 hover:bg-green-700 text-white rounded-md w-full"
                        >
                          Book Now
                        </Link>
                      </div>
                      <div className="p-1 w-1/2">
                        <Link
                          to="#"
                          className="btn bg-green-600 hover:bg-green-700 text-white rounded-md w-full"
                        >
                          Offer Now
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="mt-12 text-center">
                    <h3 className="mb-6 text-xl leading-normal font-medium text-black dark:text-white">
                      Have Question ? Get in touch!
                    </h3>

                    <div className="mt-6">
                      <Link
                        to="/contact"
                        className="btn bg-transparent hover:bg-green-600 border border-green-600 text-green-600 hover:text-white rounded-md"
                      >
                        <BsTelephone className="align-middle me-2" /> Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((photoIndex + 1) % images.length)
          }
        />
      )}

      <Footer />
      {/* <Switcher /> */}
    </>
  );
}

export default withRouter(PropertyDetail);
