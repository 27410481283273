import React from 'react';

export default function ServiceCard({ Icon, title, description }) {
  return (
    <div className="flex items-start gap-6">
      <div className="p-3 rounded-full icon-bg">
        <Icon className="w-6 h-6 text-green-600" />
      </div>
      <div>
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600 leading-relaxed">{description}</p>
      </div>
    </div>
  );
}
