import React from "react";
import {
  FiCheckSquare,
  FiClipboard,
  FiFile,
  FiHexagon,
  FiHome,
  FiMessageSquare,
  FiTarget,
  FiUsers,
} from "react-icons/fi";
import ServiceCard from "./ServiceCard";
import { FiShield, FiKey, FiCreditCard } from "react-icons/fi";
import image from "../assets/images/buildings-edge-river.jpg";

export default function FeatureTwo() {
  return (
    <>
      <div className="bg-gray-50 flex items-center justify-center py-16">
        <div className="w-[90%] lg:w-[80%] bg-white rounded-2xl shadow-xl">
          <div className="abt-card-bg">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <div className="space-y-12 px-6 py-10 lg:px-12 lg:py-10">
                <div>
                  <h1 className="text-4xl md:text-5xl font-bold text-gray-900 leading-tight mb-8">
                    Simplifying Real Estate for You
                  </h1>
                </div>

                <div className="space-y-8">
                  <ServiceCard
                    Icon={FiTarget}
                    title="Marketing Expertise"
                    description="We craft targeted marketing strategies to showcase your property to the right audience, maximizing visibility and attracting potential buyers."
                  />
                  <ServiceCard
                    Icon={FiShield}
                    title="Expert Legal Support"
                    description="Our team of experts ensures that every legal aspect is handled smoothly and efficiently, without any complications."
                  />
                  <ServiceCard
                    Icon={FiHome}
                    title="Property Management"
                    description="To enhance value and appeal, we provide maintenance solutions that keep your property in excellent condition."
                  />
                  <ServiceCard
                    Icon={FiFile}
                    title="Documentation"
                    description="We handle all documentation with accuracy and care to eliminating the stress of complex processes."
                  />
                  <ServiceCard
                    Icon={FiCreditCard}
                    title="Loan Assistance"
                    description="With our guidance, you can easily secure loans and connect you with reliable financial partners."
                  />
                </div>
              </div>

              <div className="w-full lg:w-[100%] w-100% mx-auto lg:ml-auto">
                <img
                  src={image}
                  alt="Modern home interior"
                  className="shadow-2xl w-full object-cover rounded-r-2xl aspect-[3/4]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
