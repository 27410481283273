import React, { useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import Switcher from "../component/Switcher";

import ContactImage from "../assets/images/svg/contact.svg";
import { FiHexagon, FiMapPin } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { MdMailOutline } from "react-icons/md";
import { contactFormlead } from "../actions/PropertyActions";
import { useDispatch } from "react-redux";

export default function Contact() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(contactFormlead(formData));
    // Clear the form after submission
    setFormData({
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <>
      <Navbar />
      {/* <!-- Google Map --> */}
      {/* <div className="container-fluid relative mt-20">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe title="contact-iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d244.97137891405762!2d76.6429254!3d10.7697326!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba86df864f54585%3A0x5495d848c0e86138!2sBizpole-Palakkad!5e0!3m2!1sen!2sin!4v1731658289512!5m2!1sen!2sin" style={{ border: '0' }} className="w-full h-[500px]" allowFullScreen></iframe>
                    </div>
                </div>
            </div> */}
      {/* <!-- Google Map --> */}

      {/* <!-- Start Section--> */}
      <section className="relative lg:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <img src={ContactImage} alt="" />
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:me-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-medium">
                    Get in touch !
                  </h3>

                  <form name="myForm" id="myForm" onSubmit={handleSubmit}>
                    <p className="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <label htmlFor="name" className="font-medium">
                          Your Name:
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-input mt-2"
                          placeholder="Name :"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <label htmlFor="email" className="font-medium">
                          Your Mobile:
                        </label>
                        <input
                          name="phone"
                          id="phone"
                          type="text"
                          className="form-input mt-2"
                          placeholder="Mobile :"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <label htmlFor="email" className="font-medium">
                          Your Email:
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-input mt-2"
                          placeholder="Email :"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-5">
                        <label htmlFor="subject" className="font-medium">
                          Your Question:
                        </label>
                        <input
                          name="subject"
                          id="subject"
                          className="form-input mt-2"
                          placeholder="Subject :"
                          value={formData.subject}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="mb-5">
                        <label htmlFor="comments" className="font-medium">
                          Your Comment:
                        </label>
                        <textarea
                          name="message"
                          id="comments"
                          className="form-input mt-2 textarea"
                          placeholder="Message :"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      className="btn bg-green-600 filterBtn text-white rounded-md"
                    >
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container lg:mt-24 mt-16">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="size-32 fill-green-600/5 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-green-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                  <BsTelephone className="text-3xl" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Phone</h5>
                <p className="text-slate-400 mt-3">
                  Call us today for prime properties, expert advice, and
                  exceptional real estate opportunities!
                </p>

                <div className="mt-5">
                  <Link
                    to="tel:+152534-468-854"
                    className="btn btn-link text-green-600 hover:text-green-600 after:bg-green-600 transition duration-500"
                  >
                    +91 9746374542
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="size-32 fill-green-600/5 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-green-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                  <MdMailOutline className="text-3xl" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Email</h5>
                <p className="text-slate-400 mt-3">
                  Email us for premium listings, expert insights, and exclusive
                  property opportunities!
                </p>

                <div className="mt-5">
                  <Link
                    to="mailto:info@mybrokerr.com"
                    className="btn btn-link text-green-600 hover:text-green-600 after:bg-green-600 transition duration-500"
                  >
                    info@mybrokerr.com
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="size-32 fill-green-600/5 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-green-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                  <FiMapPin className="text-3xl" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Location</h5>
                <p className="text-slate-400 mt-3">
                  Palakkad, <br /> Kerala, India
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Section--> */}

      <Footer />
      {/* <Switcher /> */}
    </>
  );
}
