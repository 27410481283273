import React from "react";
import { FiCheckSquare, FiClipboard, FiHexagon, FiMessageSquare, FiUsers } from "react-icons/fi";

export default function Feature() {

    const featureData = [
        {
            icon:FiUsers,
            title:'Discussion with Property Advisor',
            desc: 'Our journey begins with a one-on-one consultation. We’ll provide insights and guide you through further steps.'
        },
        {
            icon:FiClipboard,
            title:'Assessment & Consent',
            desc: 'After understanding, we assess your property’s market value and move forward with your approval.'
        },
        {
            icon:FiCheckSquare,
            title:'Document verification',
            desc: 'Here we ensures transparency through taking care of verifying all necessary legal documents related to your property'
        },
        {
            icon:FiMessageSquare,
            title:'Rest is ours',
            desc: 'We handle everything from managing inquiries to organizing tours and finalizing paperwork.'
        },
    ]

    return (
        <>
            <div className="container lg:mt-24 mt-16 pb-10">
                <div className="grid grid-cols-1 pb-7 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">We’ve Got You Covered!</h3>

                    {/* <p className="text-slate-400 max-w-xl mx-auto">We assist by providing online listings, virtual tours, real-time updates, and seamless property transactions.</p> */}
                </div>

                <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 mt-8 gap-[30px]">
                    {featureData.map((item,index) =>{
                        let Icon = item.icon
                        return(
                            <div className="group relative lg:px-10 transition-all duration-500 ease-in-out rounded-xl bg-transparent overflow-hidden text-center" key={index}>
                                <div className="relative overflow-hidden text-transparent -m-3">
                                    <FiHexagon className="size-32 fill-green-600/5 mx-auto" />
                                    <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-green-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                                        <Icon className="text-3xl"/>
                                    </div>
                                </div>
        
                                <div className="mt-6">
                                    <h5 className="text-xl font-medium">{item.title}</h5>
                                    <p className="text-slate-500 mt-3">{item.desc}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}
